import { StackNavigationProp } from "@react-navigation/stack";
import { Layout } from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";

import { screenStyles } from "@covid/common/src";
import { LanguageEditSection } from "@covid/common/src/organisms";
import { uploadFileToS3 } from "@covid/common/src/util";
import { types } from "@covid/service";

import { RouteParams } from "../../routes";

type LanguageAddNewTextLangScreenProps = {
  navigation: StackNavigationProp<RouteParams, "LANGUAGE_CREATE_TEXT">;
};

export const LanguageAddNewTextLangScreen: FC<LanguageAddNewTextLangScreenProps> = ({
  navigation,
}) => {
  const [flagS3Uri, setFlagS3Uri] = useState("");
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [errors, setError] = useState<string[]>([]);

  const [
    createTextLang,
    { loading: saveLoading, error: saveErrors },
  ] = types.useCreateTextLangMutation();
  const [
    createStaticTextForLang,
    { loading: saveStaticTextLoading, error: saveStaticTextError },
  ] = types.useCreateStaticTextBundleMutation();
  useEffect(() => {
    if (saveErrors) {
      setError(errors.concat(saveErrors.message));
    }
    if (saveStaticTextError) {
      setError(errors.concat(saveStaticTextError.message));
    }
  }, [saveErrors, saveStaticTextError]);
  const addLanguage = async (name: string) => {
    try {
      // TODO(ssp6): Put these as one function on backend - handled unhappy path better
      const textLang = await createTextLang({ variables: { name, flagImageUri: flagS3Uri } });
      const textLangID = textLang.data?.createTextLanguage?.id;
      if (textLangID) {
        await createStaticTextForLang({ variables: { langID: textLangID } });
      }

      setError(errors.concat("Unknown error uploading language"));
      if (!saveErrors && !saveStaticTextError) {
        navigation.navigate("CONFIRMATION", {
          confirmationText: `The text language "${name}" has been created!`,
          onContinue: () => navigation.pop(2),
        });
      }
      setError([]);
    } catch (e) {
      setError(e);
    }
  };

  const uploadLanguageImage = async (localUri: string, name: string) => {
    setUploadImageLoading(true);
    try {
      const result = await uploadFileToS3(localUri, `language-flag-${name}`);
      setFlagS3Uri(result);
    } catch (e) {
      setError(e.message);
    }

    setUploadImageLoading(false);
  };

  return (
    <Layout style={screenStyles.webCentredColumn}>
      <LanguageEditSection
        headingText={"Create New Text Language"}
        flagImageUri={flagS3Uri}
        saveButtonText={"Create Language"}
        saveAction={addLanguage}
        saveLoading={saveLoading || saveStaticTextLoading}
        uploadLanguageImage={uploadLanguageImage}
        uploadImageLoading={uploadImageLoading}
        error={errors.join(", ") || null}
        setError={(error: string) => setError(errors.concat(error))}
      />
    </Layout>
  );
};
