import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import {
  Icon,
  MenuItem,
  OverflowMenu,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";
import React, { FC, useState } from "react";

import { screenStyles } from "@covid/common/src";
import { DEV_MODE } from "@covid/common/src/constants";
import { isMobileDevice } from "@covid/common/src/constants";
import { useAmplifyAuth } from "@covid/service";

import { RouteParams } from "../routes";

type TopMobileBarProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
};

export const TopBarDrawerScreens: FC<TopMobileBarProps> = ({ navigation }) => {
  const { signOut } = useAmplifyAuth();
  const [, setLoading] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const handleSignOut = async () => {
    setLoading(true);
    await signOut();
    setLoading(false);
  };

  const renderBurgerMenuIfSmallScreen = isMobileDevice()
    ? (props: any) => (
        <TopNavigationAction
          {...props}
          onPress={() => navigation.toggleDrawer()}
          icon={(props) => <Icon {...props} name={"bars"} />}
        />
      )
    : undefined;

  const renderAccountButton = () => (
    <TopNavigationAction
      icon={(props) => <Icon {...props} name={"user"} />}
      onPress={() => setShowAccountMenu(true)}
    />
  );

  const titleText = isMobileDevice()
    ? `RefugeeHelpVirus ${DEV_MODE ? "| TEST SITE" : ""}`
    : `${DEV_MODE ? "TEST SITE — Changes won't affect users" : ""}`;

  return (
    <TopNavigation
      style={screenStyles.topBar}
      alignment={"center"}
      title={titleText}
      subtitle={isMobileDevice() ? "Admin site" : undefined}
      accessoryLeft={renderBurgerMenuIfSmallScreen}
      accessoryRight={() => (
        <OverflowMenu
          anchor={renderAccountButton}
          visible={showAccountMenu}
          onSelect={() => setShowAccountMenu(!showAccountMenu)}
          onBackdropPress={() => setShowAccountMenu(false)}
        >
          <MenuItem
            onPress={() => navigation.navigate("ADMIN_SETTINGS", { mobileView: isMobileDevice() })}
            title="Settings"
          />
          <MenuItem onPress={handleSignOut} title="Sign out" />
        </OverflowMenu>
      )}
    />
  );
};
