import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Text } from "@ui-kitten/components";
import React, { FC, useContext, useEffect, useState } from "react";

import { isMobileDevice } from "@covid/common/src/constants";
import { LangsWithDetails, BUNDLED_TEXT_LANGS, LanguageContext } from "@covid/common/src/i18n";
import {
  ChooseTranslationVisuals,
  TranslatableLanguage,
  TranslationState,
} from "@covid/common/src/organisms/ChooseTranslationVisuals";

import { TRANSLATION_TYPE, RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type AnnouncementChooseTranslationContainerProps = {
  route: RouteProp<RouteParams, "CHOOSE_TRANSLATION">;
  navigation: StackNavigationProp<
    RouteParams,
    "ANNOUNCEMENT_ADD_TEXT_TRANSLATION" | "ANNOUNCEMENT_ADD_AUDIO_TRANSLATION"
  >;
};

export const AnnouncementChooseTranslationScreen: FC<AnnouncementChooseTranslationContainerProps> = ({
  route,
  navigation,
}) => {
  const [state] = useContext(GlobalStateContext);
  const [translatableLanguages, setTranslatableLanguages] = useState<null | TranslatableLanguage[]>(
    null
  );
  const [navigationOnSelect, setNavigationOnSelect] = useState<
    "ANNOUNCEMENT_ADD_TEXT_TRANSLATION" | "ANNOUNCEMENT_ADD_AUDIO_TRANSLATION" | null
  >(null);

  const { availableTextLangsWithDetails, availableAudioLangsWithDetails } = useContext(
    LanguageContext
  );

  const getTranslationState = (
    langWithDetails: LangsWithDetails[],
    translationType: TRANSLATION_TYPE
  ): TranslatableLanguage[] => {
    //  Returns an object with language details and translated status determined by announcement in state
    return langWithDetails.map((detailedLang) => {
      const translated =
        translationType === TRANSLATION_TYPE.AUDIO
          ? state.general.currentAnnouncement.audio.some((t) => t.langID === detailedLang.id)
          : state.general.currentAnnouncement.text.some((t) => t.langID === detailedLang.id);

      const translationState: TranslationState = translated
        ? { type: "full" }
        : { type: "missing" };

      return { ...detailedLang, state: translationState };
    });
  };

  useEffect(() => {
    if (route.params.translationType === TRANSLATION_TYPE.AUDIO) {
      setTranslatableLanguages(
        getTranslationState(availableAudioLangsWithDetails, TRANSLATION_TYPE.AUDIO)
      );
      setNavigationOnSelect("ANNOUNCEMENT_ADD_AUDIO_TRANSLATION");
    } else if ((route.params.translationType = TRANSLATION_TYPE.TEXT)) {
      setTranslatableLanguages(
        getTranslationState(availableTextLangsWithDetails, TRANSLATION_TYPE.TEXT).filter(
          (t) => t.id !== BUNDLED_TEXT_LANGS.EN
        )
      );
      setNavigationOnSelect("ANNOUNCEMENT_ADD_TEXT_TRANSLATION");
    }
  }, []);

  return (
    <>
      <Text
        category={isMobileDevice() ? "h5" : "h4"}
        style={{ textAlign: "center", marginVertical: 16 }}
      >
        Select A Translation Language
      </Text>
      <ChooseTranslationVisuals
        onSelect={
          navigationOnSelect
            ? (language: TranslatableLanguage, translation?: { title: string; body: string }) =>
                navigation.navigate(navigationOnSelect, {
                  title: "Add translation",
                  language,
                  translation: translation,
                })
            : undefined
        }
        translatableLanguages={translatableLanguages || []}
        showLegend={false}
      />
    </>
  );
};
