import { RouteProp, useIsFocused } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Card, Divider, Layout, List } from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";
import { ListRenderItemInfo, View } from "react-native";

import { screenStyles } from "@covid/common/src";
import { ButtonLoading } from "@covid/common/src/atoms";
import {
  REFUGEE_APP_TUTORIAL_VIDEOS_DETAILS,
  videoTutorialDetails,
} from "@covid/common/src/constants";
import { LangsWithDetails } from "@covid/common/src/i18n";
import { LoadingFullscreen } from "@covid/common/src/molecules";
import { Callout } from "@covid/common/src/molecules/Callout";
import { VideoTutorialEdit } from "@covid/common/src/organisms";
import { types } from "@covid/service";
import {
  Maybe,
  RefugeeAppTutorialVideoNames,
  TutorialVideoTranslationInput,
} from "@covid/service/graphql/types";

import { RouteParams } from "../../routes";

type LanguageEditTutorialVideoAudioContainerProps = {
  navigation: StackNavigationProp<RouteParams, "LANGUAGE_TUTORIAL_VOICE_OVER">;
  route: RouteProp<RouteParams, "LANGUAGE_TUTORIAL_VOICE_OVER">;
};

export const LanguageEditTutorialVideoAudioScreen: FC<LanguageEditTutorialVideoAudioContainerProps> = ({
  navigation,
  route,
}) => {
  const { langID } = route.params;
  const [videosWithAudio, setVideosWithAudio] = useState(REFUGEE_APP_TUTORIAL_VIDEOS_DETAILS);
  const { data, refetch, loading: fetchAudioLoading } = types.useGetAudioLangQuery({
    variables: { langID },
  });

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused && !fetchAudioLoading) {
      refetch();
    }
  }, [isFocused]);

  useEffect(() => {
    setAudioKeyOnAllTutorialVideos();
  }, [data]);
  const setAudioKeyOnAllTutorialVideos = () => {
    if (data?.getAudioLanguage) {
      const videoWithAudioFromLangAttached = REFUGEE_APP_TUTORIAL_VIDEOS_DETAILS.map((video) => ({
        ...video,
        audioKey: ifAudioGetForVideoKey(video.id, data.getAudioLanguage),
      }));
      setVideosWithAudio(videoWithAudioFromLangAttached);
    }
  };

  // Update the audio on one tutorial video
  const updateVoiceOverOfVideo = (
    videoId: RefugeeAppTutorialVideoNames,
    audioS3Id: string | undefined
  ) => {
    const updatedVideosWithAudio = videosWithAudio.map((video) => {
      if (video.id === videoId) {
        return { ...video, audioKey: audioS3Id };
      }
      // If doesn't match then just return unedited
      return video;
    });

    setVideosWithAudio(updatedVideosWithAudio);
  };

  // Saves all to DB
  const [
    saveLanguageWithTutorialAudio,
    { error, loading },
  ] = types.useUpdateAudioLangTutorialVideoVoiceOversMutation();

  const saveLanguageWithTutorialAudioAsync = async (
    videosWithEmptyAudiosRemoves: TutorialVideoTranslationInput[]
  ) => {
    try {
      await saveLanguageWithTutorialAudio({
        variables: { langID, tutorialVideoVoiceOvers: videosWithEmptyAudiosRemoves },
      });
    } catch (e) {
      // Use errors from hook
    }
  };

  const navigateToConfirmationScreen = () => {
    return navigation.navigate("CONFIRMATION", {
      confirmationText: `Audio for tutorial videos updated successfully!`,
      onContinue: () => navigation.pop(2),
    });
  };
  // Saves all tutorials have got an audio key
  const saveTutorialAudio = async () => {
    const videosWithEmptyAudiosRemoved = removeEmptyAudiosAndGetInCorrectFormat(videosWithAudio);
    await saveLanguageWithTutorialAudioAsync(videosWithEmptyAudiosRemoved);
    navigateToConfirmationScreen();
  };

  if (loading) {
    return <LoadingFullscreen />;
  }

  return (
    <Layout style={[screenStyles.webCentredColumn, { paddingBottom: 80 }]}>
      <List
        data={videosWithAudio}
        renderItem={({ item }: ListRenderItemInfo<videoTutorialDetails>) => (
          <VideoTutorialEdit
            videoWithDetails={item}
            updateVoiceOverOfVideo={updateVoiceOverOfVideo}
            langID={langID}
          />
        )}
        ItemSeparatorComponent={() => <Divider style={{ marginVertical: 32 }} />}
      />
      <View style={screenStyles.stuckToBottomScreenWrapper}>
        <Card>
          {error?.message && <Callout text={error?.message} status={"danger"} />}
          <ButtonLoading loading={loading} onPress={saveTutorialAudio}>
            Save All
          </ButtonLoading>
        </Card>
      </View>
    </Layout>
  );
};

const ifAudioGetForVideoKey = (
  videoKey: RefugeeAppTutorialVideoNames,
  audioLang?: Maybe<LangsWithDetails> // Will never actually be null or undefined as only executed if present but ts...
) => audioLang?.videoTranslations?.find((video) => video.translationKey == videoKey)?.audio;

const removeEmptyAudiosAndGetInCorrectFormat = (
  videosWithAudio: videoTutorialDetails[]
): TutorialVideoTranslationInput[] =>
  videosWithAudio
    .filter((v) => !!v.audioKey)
    .map((video) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      audio: video.audioKey!,
      translationKey: video.id,
    }));
