import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { PENANG_COORDINATES } from "@covid/common/src/constants";
import { MapVisuals } from "@covid/common/src/organisms/MapVisuals";
import { types } from "@covid/service";
import { CovidReportType } from "@covid/service/graphql/types";
import {
  formatCovidReports,
  GenericDataItem,
  REPORT_TYPE_WITH_DETAILS,
} from "@covid/service/src/dataItemManipulation";
import { createdAtLessThanNWeeksOld } from "@covid/service/src/util";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type CovidReportMapContainerProps = {
  navigation: StackNavigationProp<RouteParams, "COVID_REPORT_MAP">;
};

/**
 * Container for map view of Covid reports using MapVisuals
 * @param navigation
 * @constructor
 */
export const CovidReportMapContainer: FC<CovidReportMapContainerProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);

  const { data, loading, refetch } = types.useListCovidReportsByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  const [selectedReport, setSelectedReport] = useState<GenericDataItem<CovidReportType>>();

  const showReport = (r?: GenericDataItem<CovidReportType>) => {
    setSelectedReport(r);
  };

  const hideReport = () => {
    setSelectedReport(undefined);
  };

  const refreshData = () => {
    setSelectedReport(undefined);
    refetch();
  };

  const NUMBER_OF_WEEKS_TO_SHOW = 6;
  return (
    <MapVisuals
      onUpdate={(report: GenericDataItem<CovidReportType>) =>
        navigation.navigate("COVID_REPORT_UPDATE", { report: report })
      }
      iconInfo={(report: GenericDataItem<CovidReportType>) =>
        REPORT_TYPE_WITH_DETAILS.find((t) => t.type === report.type)
      }
      loading={loading}
      refetch={refreshData}
      data={formatCovidReports(data).filter(createdAtLessThanNWeeksOld(NUMBER_OF_WEEKS_TO_SHOW))}
      defaultCenter={{
        latitude:
          general.currentLocation?.centreCoordinates?.latitude || PENANG_COORDINATES.latitude,
        longitude:
          general.currentLocation?.centreCoordinates?.longitude || PENANG_COORDINATES.longitude,
      }}
      title={"COVID Reports"}
      show={showReport}
      hide={hideReport}
      selected={selectedReport}
      LocationSelectWithModal={() => <LocationSelectWithModalContainer onChange={refetch} />}
      numberOfWeeksShown={NUMBER_OF_WEEKS_TO_SHOW}
    />
  );
};
