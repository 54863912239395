import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { types } from "@covid/service";
import { HelpSiteType } from "@covid/service/graphql/types";
import {
  DataItemCategory,
  getUserFriendlyGraphqlErrorMessage,
  HELP_SITE_WITH_DETAILS,
  UpdateHelpSiteProps,
  validateHelpSiteInfo,
} from "@covid/service/src/dataItemManipulation";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

import { HelpSitesEditVisuals } from "./HelpSitesEditVisuals";

type HelpSitesCreateContainerProps = {
  navigation: StackNavigationProp<RouteParams, "HELP_SITE_CREATE">;
  route: RouteProp<RouteParams, "HELP_SITE_CREATE">;
};

/**
 * Container for update screen of Help sites using HelpSitesEditVisuals
 */
export const HelpSitesCreateContainer: FC<HelpSitesCreateContainerProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [createHelpSiteMutation, { loading }] = types.useCreateHelpSiteMutation();

  const onCreate = async ({
    location,
    additionalInfo,
    type,
    emailAddress,
    organisationName,
    phoneNumber,
    title,
  }: UpdateHelpSiteProps<HelpSiteType>) => {
    const errors = validateHelpSiteInfo({
      title,
      phoneNumber,
      emailAddress,
      location,
      type,
      locationID: general.currentLocation?.id,
    });

    if (errors.length == 0) {
      try {
        //todo(jack): Better error handling
        if (type && location && title && general.currentLocation?.id) {
          await createHelpSiteMutation({
            variables: {
              additionalInformation: {
                source: additionalInfo || "",
                translationIndex: 1,
              },
              //TODO(Jack): Better typing here
              location: {
                longitude: location.longitude || 0,
                latitude: location.latitude || 0,
              },
              type: type,
              emailAddress: emailAddress === "" ? null : emailAddress,
              organisationName: organisationName,
              phoneNumber: phoneNumber === "" ? null : phoneNumber,
              title: { source: title, translationIndex: 0 },
              locationID: general.currentLocation.id,
            },
          });
        }
      } catch (e) {
        errors.push(getUserFriendlyGraphqlErrorMessage(e.message));
      }

      if (errors.length == 0) {
        navigation.navigate("CONFIRMATION", {
          confirmationText: "The help site has been created",
          onContinue: () => navigation.pop(2),
        });
      }
    }

    setValidationErrors(errors);
  };

  return (
    <HelpSitesEditVisuals
      LocationSelectWithModal={() => <LocationSelectWithModalContainer />}
      dataItem={{
        id: "",
        createdAt: "",
        category: DataItemCategory.HELP_SITE,
        location: { longitude: undefined, latitude: undefined },
        type: HelpSiteType.ClinicCheap,
      }}
      onUpdate={onCreate}
      error={validationErrors.join(", ")}
      loading={loading}
      types={HELP_SITE_WITH_DETAILS}
      navigation={navigation}
    />
  );
};
