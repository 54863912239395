import { useApolloClient } from "@apollo/react-hooks";
import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { RouteProp, useIsFocused } from "@react-navigation/native";
import React, { FC, useContext, useEffect, useState } from "react";

import { LanguageContext } from "@covid/common/src/i18n";
import {
  announcementDeleteFromAllLocations,
  fixNull,
  SORT_ORDER,
  sortByCreationDate,
  types,
} from "@covid/service";

import { TopBarDrawerScreens } from "../../molecules/TopBarDrawerScreens";
import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

import { AnnouncementsHomeVisuals } from "./AnnouncementsHomeVisuals";

type AnnouncementsHomeProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "ANNOUNCE">;
};

export const AnnouncementsHomeContainer: FC<AnnouncementsHomeProps> = ({ navigation, route }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const {
    data,
    loading: announcementFetchLoading,
    refetch,
  } = types.useListAnnouncementsByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  // Auto refetch announcements on page refocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused && !announcementFetchLoading) {
      refetch();
    }
  }, [isFocused]);

  const sortedAnnouncements = sortByCreationDate(
    fixNull(data?.announcementByLocation?.items).map((e) => e.announcement),
    SORT_ORDER.DESCENDING
  );

  const [deleting, setDeleting] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState<string | undefined>(undefined);
  const client = useApolloClient();
  const deleteAnnouncementMutation = async (announcementToDeleteID: string) => {
    try {
      setDeleting(true);
      await announcementDeleteFromAllLocations(client, announcementToDeleteID);
      setDeleting(false);
      setErrorDeleting(undefined);
    } catch (e) {
      console.log(e);
      setDeleting(false);
      setErrorDeleting(e.message);
      throw e;
    }
  };

  const [announcementToDelete, setAnnouncementToDelete] = useState<string | null>(null);

  const { availableAudioLangsWithDetails, availableTextLangsWithDetails } = useContext(
    LanguageContext
  );

  const onCreateNavigation = () => {
    navigation.navigate("CREATE_ANNOUNCEMENT");
  };

  const deleteAnnouncement = async () => {
    if (announcementToDelete) {
      try {
        await deleteAnnouncementMutation(announcementToDelete);
        setAnnouncementToDelete(null);
        navigation.navigate("CONFIRMATION", {
          confirmationText: "The announcement has been deleted",
          onContinue: () =>
            navigation.navigate("ANNOUNCE", { mobileView: route.params.mobileView }),
        });
      } catch (e) {
        /* noop */
      }
    }
  };

  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <AnnouncementsHomeVisuals
        loading={announcementFetchLoading}
        refetch={refetch}
        announcements={sortedAnnouncements}
        setAnnouncementToDelete={(announcement: string | null) =>
          setAnnouncementToDelete(announcement)
        }
        announcementToDelete={announcementToDelete}
        onCreateNavigation={onCreateNavigation}
        deleting={deleting}
        deleteAnnouncement={deleteAnnouncement}
        errorDeleting={errorDeleting}
        availableAudioLangsWithDetails={availableAudioLangsWithDetails}
        availableTextLangsWithDetails={availableTextLangsWithDetails}
        LocationSelectWithModal={() => <LocationSelectWithModalContainer onChange={refetch} />}
        navigateToTutorialVideo={() => navigation.navigate("TUTORIAL_VIDEO")}
      />
    </>
  );
};
