import { StackScreenProps } from "@react-navigation/stack";
import React, { FC } from "react";
import { View } from "react-native";

import { screenStyles } from "@covid/common/src";
import { AboutVisuals } from "@covid/common/src/organisms/AboutVisuals";

import { RouteParams } from "../routes";

type AboutScreenProps = StackScreenProps<RouteParams, "ABOUT">;

/**
 * Displays the Page that is provided in the route
 * parameters to this screen.
 *
 * @emits page-opened: when the page is opened
 * @emits page-closed: when the page is closed
 */
export const AboutScreen: FC<AboutScreenProps> = () => {
  return (
    <View style={screenStyles.webCentredColumn}>
      <AboutVisuals />
    </View>
  );
};
