import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { RouteProp, useIsFocused } from "@react-navigation/native";
import { Card, Layout, List, Text } from "@ui-kitten/components";
import React, { FC, useContext, useEffect } from "react";
import { ListRenderItem, View } from "react-native";

import { WebRefreshButton } from "@covid/common/src/atoms";
import { isMobileDevice } from "@covid/common/src/constants";
import { BUNDLED_AUDIO_LANGS, BUNDLED_TEXT_LANGS } from "@covid/common/src/i18n";
import { InformationPagePreview } from "@covid/common/src/molecules";
import { screenStyles } from "@covid/common/src/styles";
import { fixNull, SORT_ORDER, types } from "@covid/service";
import { sortById } from "@covid/service/src/dataSorting";

import { TopBarDrawerScreens } from "../molecules/TopBarDrawerScreens";
import { LocationSelectWithModalContainer } from "../organisms";
import { RouteParams } from "../routes";
import { GlobalStateContext } from "../state";

type PagesScreenProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "INFORMATION_PAGES">;
};

export const PagesScreen: FC<PagesScreenProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const { data, loading, refetch } = types.useListPagesByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentCountry?.id || "" },
  });

  // Auto refetch pages on page refocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused && !loading) {
      refetch();
    }
  }, [isFocused]);

  const pages = sortById(
    fixNull(data?.pageByLocation?.items).map((e) => e.page),
    SORT_ORDER.ASCENDING
  );

  const renderItem: ListRenderItem<types.Page> = ({ item: page, index }) => (
    <InformationPagePreview
      page={page}
      key={index}
      onPress={() => navigation.navigate("PAGE_EDIT_PREAMBLE", { page })}
      currentAudioLang={BUNDLED_AUDIO_LANGS.ROH}
      currentTextLang={BUNDLED_TEXT_LANGS.EN}
    />
  );

  // todo(arlyon) Location dropdown list
  // todo(arlyon) optimise by fetching full page separately
  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />

      <View style={screenStyles.webCentredColumn}>
        <LocationSelectWithModalContainer onChange={refetch} countriesOnly />
        <Card disabled style={{ marginTop: 10 }}>
          <Text category={"h6"}>
            Pages of information are specific to a country not just a city.
          </Text>
          <Text>This is to increase the likelihood of information being kept up to date.</Text>
        </Card>
        <List
          data={pages}
          contentContainerStyle={{ padding: -8 }}
          numColumns={2}
          onRefresh={() => refetch()}
          refreshing={loading}
          renderItem={renderItem}
          columnWrapperStyle={{ alignItems: "flex-start" }}
          ListHeaderComponent={
            <Layout
              style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
            >
              <Text category={isMobileDevice() ? "h5" : "h4"} style={{ textAlign: "center" }}>
                Pages
              </Text>
              <WebRefreshButton loading={loading} onPress={() => refetch()} />
            </Layout>
          }
          ListEmptyComponent={
            <Text category={"h5"} style={{ textAlign: "center" }}>
              No pages for locations — this should not happen. Refresh page and contact support if
              this continues
            </Text>
          }
        />
      </View>
    </>
  );
};
