import { createDrawerNavigator } from "@react-navigation/drawer";
import { DrawerContentComponentProps } from "@react-navigation/drawer/lib/typescript/src/types";
import { DrawerItem, Icon, Drawer, IndexPath, TopNavigation } from "@ui-kitten/components";
import React, { FC } from "react";

import { screenStyles } from "@covid/common/src";
import { isMobileDevice } from "@covid/common/src/constants";

import {
  LanguagesHomeScreen,
  HelpSiteMapScreen,
  AnnouncementsHomeScreen,
  PagesScreen,
  SettingsScreen,
} from "../screens";

import { CovidReportTabs } from "./CovidReportTabs";
import { SupplyRequestTabs } from "./SupplyRequestTabs";
import { RouteParams } from "./types";

const { Navigator, Screen } = createDrawerNavigator<RouteParams>();

const DrawerMenuTopBarOnWeb = () => (
  <TopNavigation
    style={screenStyles.topBar}
    alignment={"center"}
    title={"RefugeeHelpVirus"}
    subtitle={"Admin site"}
  />
);

const DrawerContent: FC<DrawerContentComponentProps> = ({ navigation, state }) => (
  <>
    {!isMobileDevice() && <DrawerMenuTopBarOnWeb />}
    <Drawer
      selectedIndex={new IndexPath(state.index)}
      onSelect={(index) => navigation.navigate(state.routeNames[index.row])}
    >
      <DrawerItem title="Announce" accessoryLeft={() => <Icon name={"bullhorn"} />} />
      <DrawerItem title="Information Pages" accessoryLeft={() => <Icon name={"file-alt"} />} />
      <DrawerItem
        title="Help Sites"
        accessoryLeft={() => <Icon name={"clinic"} pack={"custom"} />}
      />
      <DrawerItem title="Languages" accessoryLeft={() => <Icon name={"language"} />} />
      <DrawerItem
        title="Requests"
        accessoryLeft={() => <Icon pack={"custom"} name={"supplies"} />}
      />
      <DrawerItem title="Reports" accessoryLeft={() => <Icon name={"disease"} />} />
      <DrawerItem
        title="Settings"
        accessoryLeft={() => <Icon pack={"custom"} name={"settings"} />}
      />
    </Drawer>
  </>
);

export function DrawerMenu() {
  return (
    <Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      drawerType={isMobileDevice() ? "front" : "permanent"}
      drawerStyle={{ maxWidth: 200, borderRightWidth: 0 }}
    >
      <Screen
        name="ANNOUNCE"
        initialParams={{ mobileView: true }}
        component={AnnouncementsHomeScreen}
      />
      <Screen
        name="INFORMATION_PAGES"
        component={PagesScreen}
        initialParams={{ mobileView: true }}
      />
      <Screen
        name="HELP_SITES"
        component={HelpSiteMapScreen}
        initialParams={{ mobileView: true }}
      />
      <Screen
        name="LANGUAGES_HOME"
        component={LanguagesHomeScreen}
        initialParams={{ mobileView: true }}
      />
      <Screen name="REQUESTS" component={SupplyRequestTabs} initialParams={{ mobileView: true }} />
      <Screen name="REPORTS" component={CovidReportTabs} initialParams={{ mobileView: true }} />
      <Screen
        name="ADMIN_SETTINGS"
        component={SettingsScreen}
        initialParams={{ mobileView: true }}
      />
    </Navigator>
  );
}
