import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Icon, TopNavigation, TopNavigationAction } from "@ui-kitten/components";
import React, { FC } from "react";

import { deserialize, TranslatedPageVisitor } from "@covid/common";

import { RouteParams } from "../routes";

type ViewPageScreenProps = {
  navigation: StackNavigationProp<RouteParams, "PAGE_VIEW">;
  route: RouteProp<RouteParams, "PAGE_VIEW">;
};

/**
 * Displays the Page that is provided in the route
 * parameters to this screen.
 *
 * @emits page-opened: when the page is opened
 * @emits page-closed: when the page is closed
 */
export const PageViewScreen: FC<ViewPageScreenProps> = ({ navigation, route }) => {
  let page = null;
  if (route.params.type === "serialized") {
    const { text, audio, page: deserializedPage } = deserialize(route.params.page);
    const visitor = new TranslatedPageVisitor(text, audio);
    page = visitor.visit(deserializedPage);
  } else {
    const visitor = new TranslatedPageVisitor({}, {});
    page = visitor.visit(route.params.block);
  }

  return (
    <>
      <TopNavigation
        title="Page Preview"
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={() => navigation.pop()}
          />
        )}
      />
      {page}
    </>
  );
};
