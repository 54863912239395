import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { DEFAULT_ANNOUNCEMENT_CONTENT, DEFAULT_ANNOUNCEMENT_TITLE } from "@covid/service";

import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

import { AnnouncementCreateVisuals } from "./AnnouncementCreateVisuals";

type CreateAnnouncementPageProps = {
  navigation: StackNavigationProp<RouteParams, "CREATE_ANNOUNCEMENT">;
};

export const AnnouncementCreateContainer: FC<CreateAnnouncementPageProps> = ({ navigation }) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const createAnnouncement = async (title: string, body: string) => {
    const errors = [];

    if (!title) {
      errors.push("Need to include a title!");
    }

    if (!body) {
      errors.push("Need to include a body!");
    }

    if (errors.length == 0)
      try {
        dispatch(
          GeneralActionCreators.updateAnnouncement({
            ...state.general.currentAnnouncement,
            title: {
              translationIndex: DEFAULT_ANNOUNCEMENT_TITLE.translationIndex,
              source: title,
            },
            content: {
              translationIndex: DEFAULT_ANNOUNCEMENT_CONTENT.translationIndex,
              source: body,
            },
          })
        );

        //TODO(Jack): Change navigation params and types to use languages available to admin
        navigation.navigate("UPLOAD_ANNOUNCEMENT");
      } catch (e) {
        setValidationErrors([e.message]);
      }

    setValidationErrors(errors);
  };

  return (
    <AnnouncementCreateVisuals
      createFunction={createAnnouncement}
      error={validationErrors.join(", ")}
      originalTitle={state.general.currentAnnouncement.title?.source}
      originalBody={state.general.currentAnnouncement.content?.source}
    />
  );
};
