import { StackNavigationProp } from "@react-navigation/stack";
import { Layout } from "@ui-kitten/components";
import React, { FC, useContext } from "react";
import { ListRenderItemInfo, ScrollView } from "react-native";

import { ReportItem } from "@covid/common/src/molecules/ReportItem";
import { DataList } from "@covid/common/src/organisms";
import { screenStyles } from "@covid/common/src/styles";
import { types } from "@covid/service";
import { RequestType } from "@covid/service/graphql/types";
import {
  formatSupplyRequests,
  GenericDataItem,
  REQUEST_TYPE_WITH_DETAILS,
} from "@covid/service/src/dataItemManipulation";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type SupplyRequestListScreenProps = {
  navigation: StackNavigationProp<RouteParams, "SUPPLY_REQUEST_LIST">;
};

/**
 * The list view for supply requests using DataList component
 * @param navigation
 * @constructor
 */
export const SupplyRequestListScreen: FC<SupplyRequestListScreenProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const { data, loading, refetch } = types.useListSupplyRequestsByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  return (
    <ScrollView>
      <Layout style={[screenStyles.webCentredColumnNarrow, { marginTop: 10 }]}>
        <LocationSelectWithModalContainer onChange={refetch} />
        <DataList
          noDataMessage={"No previous requests"}
          title={"Supply requests"}
          renderItem={({ item }: ListRenderItemInfo<GenericDataItem<RequestType>>) => (
            <ReportItem
              report={item}
              onPress={() => navigation.navigate("SUPPLY_REQUEST_UPDATE", { report: item })}
              reportInfo={REQUEST_TYPE_WITH_DETAILS.find((t) => t.type === item.type)}
            />
          )}
          data={formatSupplyRequests(data)}
          refreshing={loading}
          onRefresh={() => refetch()}
        />
      </Layout>
    </ScrollView>
  );
};
