import { StackScreenProps } from "@react-navigation/stack";
import React, { FC } from "react";

import { ManageTextTranslation } from "@covid/common/src/molecules/ManageTextTranslation";

import { RouteParams } from "../routes";

type PageManageTextTranslationsScreenProps = StackScreenProps<
  RouteParams,
  "PAGE_TRANSLATE_TEXT_MANAGE"
>;

export const PageManageTextTranslationsScreen: FC<PageManageTextTranslationsScreenProps> = ({
  navigation,
  route,
}) => {
  const { langName, translations, source, onSave } = route.params;

  return (
    <ManageTextTranslation
      languageName={langName}
      onSave={(updates) => onSave(updates)}
      onCancel={() => navigation.pop()}
      sources={source}
      translations={translations?.translations || {}}
      loading={false}
    />
  );
};
