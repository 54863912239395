import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useEffect, useState } from "react";

import { ManageTextTranslation } from "@covid/common/src/molecules/ManageTextTranslation";
import {
  DEFAULT_ANNOUNCEMENT_CONTENT,
  DEFAULT_ANNOUNCEMENT_TITLE,
  getTextTranslation,
} from "@covid/service";

import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

type AddTextTranslationContainerProps = {
  navigation: StackNavigationProp<RouteParams, "ANNOUNCEMENT_ADD_TEXT_TRANSLATION">;
  route: RouteProp<RouteParams, "ANNOUNCEMENT_ADD_TEXT_TRANSLATION">;
};

export const AnnouncementAddTextTranslationScreen: FC<AddTextTranslationContainerProps> = ({
  navigation,
  route,
}) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [currentTranslationTitle, setTitle] = useState("");
  const [currentTranslationContent, setContent] = useState("");

  const { title, content } = state.general.currentAnnouncement;
  const sources = {
    [title.translationIndex]: title.source,
    [content.translationIndex]: content.source,
  };

  const translations = {
    [title.translationIndex]: currentTranslationTitle,
    [content.translationIndex]: currentTranslationContent,
  };

  useEffect(() => {
    setTitle(
      getTextTranslation(
        DEFAULT_ANNOUNCEMENT_TITLE,
        state.general.currentAnnouncement.text,
        route.params.language.id
      )
    );
    setContent(
      getTextTranslation(
        DEFAULT_ANNOUNCEMENT_CONTENT,
        state.general.currentAnnouncement.text,
        route.params.language.id
      )
    );
  }, [state.general.currentAnnouncement.text]);

  const addTextTranslation = async (translations: { [key: number]: string }) => {
    const errors = [];
    const titleText = translations[title.translationIndex];
    const contentText = translations[content.translationIndex];

    if (!titleText) {
      errors.push("Need to include a title!");
    }

    if (!contentText) {
      errors.push("Need to include a body!");
    }

    if (errors.length == 0) {
      //TODO(Jack): Check if language already present

      const stateTextWithoutCurrLang = state.general.currentAnnouncement.text.filter(
        (t) => t.langID !== route.params.language.id
      );

      dispatch(
        GeneralActionCreators.updateAnnouncement({
          ...state.general.currentAnnouncement,
          text: [
            ...stateTextWithoutCurrLang,
            {
              id: route.params.language.id,
              langID: route.params.language.id,
              outOfDate: false,
              translations: [
                {
                  translationIndex: DEFAULT_ANNOUNCEMENT_TITLE.translationIndex,
                  text: titleText,
                },
                {
                  translationIndex: DEFAULT_ANNOUNCEMENT_CONTENT.translationIndex,
                  text: contentText,
                },
              ],
            },
          ],
        })
      );

      navigation.navigate("UPLOAD_ANNOUNCEMENT");
    }

    setValidationErrors(errors);
  };

  return (
    <ManageTextTranslation
      languageName={route.params.language.name}
      onSave={(updates) => addTextTranslation(updates)}
      onCancel={() => navigation.pop()}
      sources={sources}
      translations={translations}
      loading={false}
      error={validationErrors.join(", ")}
    />
  );
};
