import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  TopNavigation,
  TopNavigationAction,
  Icon,
  Button,
  Toggle,
  Text,
} from "@ui-kitten/components";
import gql from "graphql-tag";
import React, { FC, useState } from "react";
import { View, Platform, StyleSheet } from "react-native";

import { deserialize, screenStyles } from "@covid/common";
import { useUpdatePageMutation, Page } from "@covid/service/graphql/types";

import { RouteParams } from "../routes";

type EditPagePreambleScreenProps = {
  navigation: StackNavigationProp<RouteParams, "PAGE_EDIT_PREAMBLE">;
  route: RouteProp<RouteParams, "PAGE_EDIT_PREAMBLE">;
};

export const PageEditPreambleScreen: FC<EditPagePreambleScreenProps> = ({ navigation, route }) => {
  const page = route.params.page;
  const [published, setPublished] = useState(page.published);

  const [savePage] = useUpdatePageMutation();

  const savePageUpdates = async (serializedPage: Page, forward = true) => {
    // these are invalid fields on the input object
    delete serializedPage.updatedAt;
    delete serializedPage.createdAt;

    setPublished(serializedPage.published);

    // eslint-disable-next-line no-useless-catch
    try {
      // we can re-run this because it is idempotent
      const { data } = await savePage({
        variables: {
          page: serializedPage,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updatePage: {
            __typename: "Page",
            ...serializedPage,
          },
        },
        update: (proxy) => {
          proxy.writeFragment({
            id: serializedPage.id,
            fragment: gql`
              fragment PublishedPage on Page {
                published
              }
            `,
            data: {
              published: serializedPage.published,
            },
          });
        },
      });

      // this should never trigger, but is here for safety
      if (data === undefined || data.updatePage === null || data.updatePage === undefined) {
        console.error("Saving page returned no id.");
        return;
      }
    } catch (e) {
      // todo(arlyon): can we handle this better?
      setPublished(published);
      throw e;
    }

    if (forward) {
      navigation.navigate("CONFIRMATION", {
        confirmationText: "Your page has been updated.",
        onContinue: () => navigation.popToTop(),
      });
    }
  };

  return (
    <View style={{ height: Platform.OS === "web" ? "100vh" : "100%" }}>
      <TopNavigation
        title="Editing Page"
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={() => navigation.pop()}
          />
        )}
      />
      <View style={screenStyles.webCentredColumn}>
        <Text category={"h4"}>{page?.title.source || ""}</Text>
        <Toggle
          style={[styles.button, styles.toggle]}
          status="success"
          checked={published === true}
          onChange={(value) => savePageUpdates({ ...page, published: value }, false)}
        >
          Published
        </Toggle>
        <Button
          style={styles.button}
          onPress={page != undefined ? () => navigation.navigate("PAGE_EDIT", { page }) : undefined}
        >
          Edit Content
        </Button>
        <Button
          style={styles.button}
          onPress={
            page != undefined
              ? () =>
                  navigation.navigate("PAGE_TRANSLATE", {
                    page: deserialize(page),
                    onSave: savePageUpdates,
                  })
              : undefined
          }
        >
          Manage Translations
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 16,
  },
  toggle: {
    height: 64,
  },
});
