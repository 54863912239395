import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext } from "react";

import { LanguageContext } from "@covid/common/src/i18n";

import { TRANSLATION_TYPE, RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

import { AnnouncementSummaryVisuals } from "./AnnouncementSummaryVisuals";

type AnnouncementSummaryContainerProps = {
  navigation: StackNavigationProp<RouteParams, "UPLOAD_ANNOUNCEMENT">;
};

/**
 * Shows the summary of the announcement before it is posted,
 * and allows checking over before upload.
 *
 */
export const AnnouncementSummaryContainer: FC<AnnouncementSummaryContainerProps> = ({
  navigation,
}) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const { availableTextLangsWithDetails, availableAudioLangsWithDetails } = useContext(
    LanguageContext
  );

  const navigateToSelectLocation = async () => {
    navigation.navigate("ANNOUNCEMENT_SELECT_LOCATION");
  };

  const removeAnnouncementTranslation = (audio: boolean, langID: string) => {
    if (audio) {
      const stateAudioWithoutCurrLang = state.general.currentAnnouncement.audio.filter(
        (t) => t.langID !== langID
      );

      dispatch(
        GeneralActionCreators.updateAnnouncement({
          ...state.general.currentAnnouncement,
          audio: stateAudioWithoutCurrLang,
        })
      );
    } else {
      const stateTextWithoutCurrLang = state.general.currentAnnouncement.text.filter(
        (t) => t.langID !== langID
      );

      dispatch(
        GeneralActionCreators.updateAnnouncement({
          ...state.general.currentAnnouncement,
          text: stateTextWithoutCurrLang,
        })
      );
    }
  };

  return (
    <AnnouncementSummaryVisuals
      navigateToSelectLocation={navigateToSelectLocation}
      sourceTitle={state.general.currentAnnouncement.title.source}
      sourceContent={state.general.currentAnnouncement.content.source}
      textTranslations={state.general.currentAnnouncement.text}
      audioTranslations={state.general.currentAnnouncement.audio}
      availableTextLangs={availableTextLangsWithDetails}
      availableAudioLangs={availableAudioLangsWithDetails}
      onAddText={() =>
        navigation.navigate("CHOOSE_TRANSLATION", {
          title: "Announcement text translation",
          translationType: TRANSLATION_TYPE.TEXT,
        })
      }
      onAddAudio={() =>
        navigation.navigate("CHOOSE_TRANSLATION", {
          title: "Announcement audio translation",
          translationType: TRANSLATION_TYPE.AUDIO,
        })
      }
      onRemove={(audio: boolean, langID: string) => removeAnnouncementTranslation(audio, langID)}
    />
  );
};
