import { Amplify } from "@aws-amplify/core";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import React, { useContext, useEffect, useState, FC, useMemo } from "react";
import { ApolloProvider } from "react-apollo";

import { LanguageContext } from "@covid/common/src/i18n";
import { LoadingFullscreen } from "@covid/common/src/molecules";
import {
  useAmplifyAuth,
  authenticatedLink,
  unAuthenticatedLink,
  types,
  fixNull,
  awsconfig,
  sortByCreationDate,
} from "@covid/service";

import { GlobalStateContext } from "../state";
import { GeneralActionCreators } from "../state/general";

Amplify.configure(awsconfig);
const cache = new InMemoryCache({ addTypename: false });

export const LoadStateProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [{ general }, dispatch] = useContext(GlobalStateContext);
  const { setAvailableStaticTextAndTextLangs, setAvailableAudioLangs } = useContext(
    LanguageContext
  );
  const { loading: authLoading, authenticated } = useAmplifyAuth();
  const [textLoading, setTextLoading] = useState(true);
  const [audioLoading, setAudioLoading] = useState(true);

  const loading = authLoading || textLoading || audioLoading;

  const client = useMemo(
    () =>
      new ApolloClient({
        link: authenticated ? authenticatedLink : unAuthenticatedLink,
        cache,
      }),
    [authenticated]
  );

  // Load default location
  useEffect(() => {
    if (!general.currentLocation && authenticated) {
      loadAvailableLocationsAndSetsDefaultInState();
    }
  }, [authenticated]);

  // Loads static text and audio langs
  useEffect(() => {
    loadTextAndAudioLangs();
  }, [client]);

  const loadAvailableLocationsAndSetsDefaultInState = async () => {
    try {
      const result = await client.query<types.ListLocationsQuery>({
        query: types.ListLocationsDocument,
      });
      const availableLocations = fixNull(result.data.listAdministrationLocations?.items);
      const firstCityToBeAdded = sortByCreationDate(
        availableLocations.filter((l) => l.level === types.LocationLevel.City)
      )[0];

      const firstCountryToBeAdded = sortByCreationDate(
        availableLocations.filter((l) => l.level === types.LocationLevel.Country)
      )[0];

      if (firstCityToBeAdded && firstCountryToBeAdded) {
        dispatch(GeneralActionCreators.locationUpdate(firstCityToBeAdded));
        dispatch(GeneralActionCreators.countryUpdate(firstCountryToBeAdded));
      }
    } catch (e) {
      // TODO(ssp6): Handle unhappy path, poup or something
      console.log(e);
    }
  };

  const loadTextAndAudioLangs = async () => {
    const promises: Promise<any>[] = [setStaticText(), setAudioLangs()];

    try {
      await Promise.all(promises);
    } catch {
      /* ignore */
    }
  };

  const setStaticText = async () => {
    try {
      const result = await client.query<types.ListStaticTextQuery>({
        query: types.ListStaticTextDocument,
      });
      const staticText = fixNull(result.data.listStaticTextBundles?.items);
      setAvailableStaticTextAndTextLangs(staticText);
      setTextLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const setAudioLangs = async () => {
    try {
      const result = await client.query<types.ListAudioLangsQuery>({
        query: types.ListAudioLangsDocument,
      });
      const audioLangs = fixNull(result.data.listAudioLanguages?.items);
      setAvailableAudioLangs(audioLangs);
      setAudioLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return loading ? (
    <LoadingFullscreen />
  ) : (
    // @ts-ignore(arlyon): Apollo complains about the cache type
    <ApolloProvider client={client}>{children}</ApolloProvider>
  );
};
