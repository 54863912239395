import React, { FC, useState } from "react";

import {
  BUNDLED_TEXT_LANGS,
  LanguageContext,
  REFUGEE_APP_STATIC_TEXT,
  BUNDLED_AUDIO_LANGS,
  TEXT_LANGS_WITH_DETAILS,
  AUDIO_LANGS_WITH_DETAILS,
  LangID,
  StaticTextWithLangsType,
} from "@covid/common/src/i18n";

/**
 * Stripped back version of the language provider for the refugee app
 * that only really needs to know about which languages have been
 * loaded in from the server and are available
 *
 * @param children
 * @constructor
 */
export const LanguageProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentTextLang, setTextLang] = useState<LangID>(BUNDLED_TEXT_LANGS.EN);
  const [currentAudioLang, setAudioLang] = useState<LangID>(BUNDLED_AUDIO_LANGS.ROH);
  const [availableTextLangsWithDetails, setAvailableTextLangs] = useState(TEXT_LANGS_WITH_DETAILS);
  const [availableAudioLangsWithDetails, setAvailableAudioLangs] = useState(
    AUDIO_LANGS_WITH_DETAILS
  );

  const textLangContext = {
    translateStaticText: (word: REFUGEE_APP_STATIC_TEXT) => ({ text: word }),
    currentTextLang,
    setTextLang,
    currentAudioLang,
    setAudioLang,
    availableTextLangsWithDetails,
    availableAudioLangsWithDetails,
    setAvailableTextLangs,
    setAvailableAudioLangs,
    setAvailableStaticTextAndTextLangs: (staticTextWithLangs: StaticTextWithLangsType[]) => {
      const langsWithDetails = staticTextWithLangs.map((bundleWithLang) => bundleWithLang.lang);
      setAvailableTextLangs(TEXT_LANGS_WITH_DETAILS.concat(langsWithDetails));
    },
    setAvailableStaticAudioAndAudioLangs: (staticAudioWithLangs: StaticTextWithLangsType[]) => {
      const langsWithDetails = staticAudioWithLangs.map((bundleWithLang) => bundleWithLang.lang);
      setAvailableAudioLangs(langsWithDetails);
    },
  };
  return <LanguageContext.Provider value={textLangContext}>{children}</LanguageContext.Provider>;
};
