import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { TopNavigation, TopNavigationAction, Icon, Text } from "@ui-kitten/components";
import React, { FC, useState } from "react";
import { View, ScrollView, Platform } from "react-native";

import { deserialize } from "@covid/common";
import { applyEdits, Edit } from "@covid/common/src/page-parser/edit";
import { Block, TranslateableText } from "@covid/common/src/page-parser/types";
import { EditPageVisitor } from "@covid/common/src/page-parser/visitor";

import { RouteParams } from "../routes";

type EditPageScreenProps = {
  navigation: StackNavigationProp<RouteParams, "PAGE_EDIT">;
  route: RouteProp<RouteParams, "PAGE_EDIT">;
};

export const PageEditScreen: FC<EditPageScreenProps> = ({ navigation, route }) => {
  const serializedPage = route.params.page;
  const deserializedPage = deserialize(serializedPage);
  const { audio, text, meta } = deserializedPage;

  const [editHistory, setEditHistory] = useState<Edit<any>[]>([
    {
      type: "replace",
      page: deserializedPage.page,
    },
  ]);
  const [historyIndex, setHistoryIndex] = useState<number>(0);

  const page = applyEdits(deserializedPage.page, editHistory.slice(0, historyIndex + 1));
  const [maxIndex, setMaxIndex] = useState(
    Math.max(
      ...page.elements
        // @ts-ignore(arlyon) we want all defined translation indices
        .map((elem) => elem.text?.translationIndex)
        .filter((index) => index !== undefined)
    )
  );
  const canUndo = historyIndex !== 0;
  const canRedo = historyIndex + 1 !== editHistory.length;

  const getIndex = () => {
    try {
      return maxIndex + 1;
    } finally {
      setMaxIndex(maxIndex + 1);
    }
  };

  const addEdit = <T extends Block>(newEdit: Edit<T>) => {
    if (historyIndex === null) {
      setEditHistory([newEdit]);
      setHistoryIndex(0);
    } else {
      setEditHistory([...editHistory.slice(0, historyIndex + 1), newEdit]);
      setHistoryIndex(historyIndex + 1);
    }
  };

  const assignNewTranslationIndex = <T extends Block>(block: T): T => {
    // @ts-ignore: ignore when text is not defined
    if (block.text === undefined) return block;

    // @ts-ignore: we already exited above
    const { source }: TranslateableText = block.text;

    return {
      ...block,
      text: {
        source,
        translationIndex: getIndex(),
      },
    };
  };

  const onMoveDown = (index: number) => {
    if (index + 1 === page.elements.length) return;
    addEdit({
      type: "move-block",
      fromIndex: index,
      toIndex: index + 1,
    });
  };

  const onMoveUp = (index: number) => {
    if (index === 0) return;
    addEdit({
      type: "move-block",
      fromIndex: index,
      toIndex: index - 1,
    });
  };

  const onSave = () => {
    navigation.navigate("PAGE_SAVE", {
      page: { audio, text, meta, page },
      edits: editHistory.slice(0, historyIndex + 1),
    });
  };

  const onNew = () => {
    navigation.navigate("PAGE_BLOCK_EDIT", {
      block: null,
      onSave: <T extends Block>(newBlock: T) =>
        addEdit({
          type: "create-block",
          index: page.elements.length,
          block: assignNewTranslationIndex(newBlock),
        }),
    });
  };

  const onEdit = (index: number) =>
    navigation.navigate("PAGE_BLOCK_EDIT", {
      block: page.elements[index],
      onSave: <T extends Block>(newBlock: T) =>
        addEdit({
          type: "update-block",
          index,
          block: newBlock,
        }),
    });

  const onDelete = (index: number) => addEdit({ type: "delete-block", index });

  const onExit = () => {
    if (historyIndex === 0) return navigation.pop();

    if (confirm("You have unsaved edits, are you sure you want to quit?")) {
      navigation.pop();
    }
  };

  const visitor = new EditPageVisitor({
    onNew,
    onSave,
    commands: {
      onEdit,
      onDelete,
      onMoveDown,
      onMoveUp,
    },
  });

  return (
    <View style={{ height: Platform.OS === "web" ? "100vh" : "100%" }}>
      <TopNavigation
        title="Editing Page"
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={onExit}
          />
        )}
        accessoryRight={(props) => (
          <>
            <TopNavigationAction
              disabled={!canUndo}
              {...props}
              icon={(props) => <Icon {...props} name="undo" />}
              onPress={() => setHistoryIndex(historyIndex - 1)}
            />
            <Text style={{ fontFamily: "monospace", fontWeight: "bold", color: "white" }}>{`${
              historyIndex + 1
            }/${editHistory.length}`}</Text>
            <TopNavigationAction
              disabled={!canRedo}
              {...props}
              icon={(props) => <Icon {...props} name="redo" />}
              onPress={() => setHistoryIndex(historyIndex + 1)}
            />
          </>
        )}
      />
      <ScrollView>{visitor.visit(page)}</ScrollView>
    </View>
  );
};
