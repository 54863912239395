import { RouteProp, useIsFocused } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useEffect, useState } from "react";

import { REFUGEE_APP_STATIC_TEXT, SOURCE_TEXT } from "@covid/common/src/i18n/static-text";
import { uploadFileToS3 } from "@covid/common/src/util";
import { fixNull, types, useDeleteLang } from "@covid/service";

import { RouteParams } from "../../routes";

import { LanguageEditIncludingStaticVisuals } from "./LanguageEditIncludingStaticVisuals";

/**
 * Screen that allows the user to edit details about a
 * language as well as edit the static text/audio in that language
 * OR be directed to edit the tutorial video voice overs.
 */
type LanguageEditWithStaticTextContainerProps = {
  navigation: StackNavigationProp<RouteParams, "LANGUAGE_EDIT">;
  route: RouteProp<RouteParams, "LANGUAGE_EDIT">;
};
export const LanguageEditIncludingStaticContainer: FC<LanguageEditWithStaticTextContainerProps> = ({
  navigation,
  route,
}) => {
  const { updateAudio, language } = route.params;
  // Store text and audio translations
  const [translations, setTranslations] = useState("{}");

  const { data: staticTextData, refetch: refetchTextTranslations } = types.useListStaticTextQuery({
    fetchPolicy: "cache-and-network",
  });
  const {
    data: staticAudioData,
    refetch: refetchAudioTranslations,
  } = types.useListStaticAudioQuery({
    fetchPolicy: "cache-and-network",
  });

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      updateStaticLangs();
    }
  }, [isFocused]);

  // Updates the translations
  const updateStaticLangs = () => {
    refetchTextTranslations();
    refetchAudioTranslations();
  };

  useEffect(() => {
    updateAudio
      ? setTranslations(
          fixNull(staticAudioData?.listStaticAudioBundles?.items).find(
            (t) => language.id === t.lang.id
          )?.translations || "{}"
        )
      : setTranslations(
          fixNull(staticTextData?.listStaticTextBundles?.items).find(
            (t) => language.id === t.lang.id
          )?.translations || "{}"
        );
  }, [staticTextData]);

  // Language Flag Functions
  const [languageDetailsErrors, setLanguageDetailsError] = useState<string | null>(null);

  const [flagImageUri, setFlagImageUri] = useState<string>(language.flagImageUri);
  const [flagUploadLoading, setFlagUploadLoading] = useState(false);

  const uploadLanguageImage = async (localUri: string, name: string) => {
    setFlagUploadLoading(true);
    try {
      const result = await uploadFileToS3(localUri, `language-flag-${name}`);
      setFlagImageUri(result);
    } catch (e) {
      setLanguageDetailsError(e.message);
    }

    setFlagUploadLoading(false);
  };

  // ***** Update Language Details ***** //

  const [confirmationOfUpdatingLangModalVisible, setConfirmationModalOpen] = useState(false);

  // Update Text Lang
  const [updateTextLang, { loading: langSaveLoadingText }] = types.useUpdateTextLangMutation();

  // Update Audio Lang
  const [updateAudioLang, { loading: langSaveLoadingAudio }] = types.useUpdateAudioLangMutation();

  const saveLanguageDetails = async (name: string) => {
    try {
      updateAudio
        ? await updateAudioLang({
            variables: { id: language.id, name, flagImageUri: flagImageUri },
          })
        : await updateTextLang({
            variables: { id: language.id, name, flagImageUri: flagImageUri },
          });
      setConfirmationModalOpen(true);
      setLanguageDetailsError(null);
    } catch (e) {
      setLanguageDetailsError(e);
    }
  };

  // ***** Update Translations Functions *****
  const [errorsTranslations, setErrorsTranslation] = useState<null | string>(null);
  const [
    updateStaticText,
    { loading: staticTextUpdateLoading },
  ] = types.useUpdateStaticTextBundleMutation();
  const [
    updateStaticAudio,
    { loading: staticAudioUpdateLoading },
  ] = types.useUpdateStaticAudioBundleMutation();

  /**
   * Updates the static text/audio in db
   * @param userTranslations
   */
  const saveTranslations = async (
    userTranslations: { [key in REFUGEE_APP_STATIC_TEXT]?: string }
  ) => {
    try {
      if (language.id) {
        updateAudio
          ? await updateStaticAudio({
              variables: {
                id: language.id,
                translations: JSON.stringify(userTranslations),
              },
            })
          : await updateStaticText({
              variables: {
                id: language.id,
                translations: JSON.stringify(userTranslations),
              },
            });
      }
    } catch (e) {
      setErrorsTranslation(e.message);
      return;
    }

    navigation.navigate("CONFIRMATION", {
      confirmationText: `The ${language.name} ${updateAudio ? "audio" : "text"} has been updated`,
      onContinue: () => navigation.pop(2),
    });
  };

  // Deleting Langs
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLangMutation, { deletingError, deletingLoading }] = useDeleteLang(
    !!updateAudio,
    language.id
  );

  const deleteLang = async () => {
    try {
      await deleteLangMutation();
    } catch (e) {
      setErrorsTranslation(e.message);
      return setDeleteModalVisible(false);
    }

    setDeleteModalVisible(false);
    navigation.navigate("CONFIRMATION", {
      confirmationText: `The ${language?.name} ${
        updateAudio ? "audio" : "text"
      } and the associated language has been deleted`,
      onContinue: () => navigation.pop(2),
    });
  };

  const openEditVideoVoiceOvers = () => {
    navigation.navigate("LANGUAGE_TUTORIAL_VOICE_OVER", { langID: language.id });
  };

  return (
    <LanguageEditIncludingStaticVisuals
      // Language Details
      flagImageUri={flagImageUri}
      saveLanguageDetails={saveLanguageDetails}
      languageSaveLoading={langSaveLoadingText || langSaveLoadingAudio}
      uploadFlagImage={uploadLanguageImage}
      flagUploadLoading={flagUploadLoading}
      languageDetailsErrors={languageDetailsErrors}
      setLanguageDetailsError={setLanguageDetailsError}
      confirmationOfUpdatingLangModalVisible={confirmationOfUpdatingLangModalVisible}
      closeConfirmationModal={() => setConfirmationModalOpen(false)}
      // Translations
      loading={staticTextUpdateLoading || staticAudioUpdateLoading}
      translations={JSON.parse(translations)}
      sourceText={SOURCE_TEXT}
      onSave={saveTranslations}
      errorsTranslations={errorsTranslations}
      // General
      languageName={language.name}
      updateAudio={updateAudio}
      // Delete
      deleteModalVisible={deleteModalVisible}
      setDeleteModalVisible={setDeleteModalVisible}
      deleteLang={deleteLang}
      deletingError={deletingError}
      deletingLoading={deletingLoading}
      // Voice overs
      openEditVideoVoiceOvers={openEditVideoVoiceOvers}
    />
  );
};
