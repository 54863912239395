import { Button, Divider, Icon, Layout } from "@ui-kitten/components";
import React, { FC } from "react";
import { ListRenderItemInfo, ScrollView } from "react-native";

import { LangsWithDetails } from "@covid/common/src/i18n";
import { AnnouncementAdminItem } from "@covid/common/src/molecules/AnnouncementAdminItem";
import { InformationModal } from "@covid/common/src/organisms";
import { DataList } from "@covid/common/src/organisms";
import { screenStyles } from "@covid/common/src/styles";
import { Announcement } from "@covid/service/graphql/types";

export type AnnouncementPageVisualsProps = {
  loading: boolean;
  refetch: () => void;
  announcements: Announcement[];
  setAnnouncementToDelete: (announcement: string | null) => void;
  announcementToDelete: string | null;
  onCreateNavigation: () => void;
  deleting: boolean;
  errorDeleting?: string;
  deleteAnnouncement: () => Promise<void>;
  availableAudioLangsWithDetails: LangsWithDetails[];
  availableTextLangsWithDetails: LangsWithDetails[];
  LocationSelectWithModal: FC;
  navigateToTutorialVideo: () => void;
};

export const AnnouncementsHomeVisuals: FC<AnnouncementPageVisualsProps> = ({
  refetch,
  loading,
  announcements,
  setAnnouncementToDelete,
  announcementToDelete,
  onCreateNavigation,
  deleting,
  errorDeleting,
  deleteAnnouncement,
  availableAudioLangsWithDetails,
  availableTextLangsWithDetails,
  LocationSelectWithModal,
  navigateToTutorialVideo,
}) => {
  const requestAnnouncementDelete = (id: string) => {
    setAnnouncementToDelete(id);
  };

  const renderDeletionConfirmationModal = () => (
    <InformationModal
      visibility={announcementToDelete !== null}
      displayText={`Are you sure you want to delete this announcement? This will remove the announcement for all users in all locations, deleting the associated text and audio translations.`}
      onCancel={() => setAnnouncementToDelete(null)}
      onSubmit={deleteAnnouncement}
      submitText={"Delete"}
      loading={deleting}
      error={errorDeleting}
    />
  );

  return (
    <ScrollView>
      <Layout style={screenStyles.webCentredColumn}>
        <Button
          style={screenStyles.spacedButton}
          onPress={navigateToTutorialVideo}
          appearance={"outline"}
          accessoryLeft={(props) => <Icon name="file" pack="solid" {...props} />}
          accessoryRight={(props) => <Icon name="arrow-right" pack="solid" {...props} />}
        >
          Tutorial / Guide
        </Button>
        <Divider />
        <Button style={screenStyles.spacedButton} size="giant" onPress={onCreateNavigation}>
          Create Announcement
        </Button>
        <LocationSelectWithModal />
        <DataList
          title={"Previous Announcements"}
          noDataMessage={"No previous announcements for location."}
          renderItem={({ item }: ListRenderItemInfo<Announcement>) => (
            <AnnouncementAdminItem
              onDelete={requestAnnouncementDelete}
              announcement={item}
              availableAudioLangsWithDetails={availableAudioLangsWithDetails}
              availableTextLangsWithDetails={availableTextLangsWithDetails}
            />
          )}
          data={announcements}
          refreshing={loading}
          onRefresh={() => refetch()}
        />
      </Layout>
      {renderDeletionConfirmationModal()}
    </ScrollView>
  );
};
