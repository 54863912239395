import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useState } from "react";

import { isMobileDevice } from "@covid/common/src/constants";
import { createNewAdmin, createNewAdminProps } from "@covid/service/src/adminActionApiFunctions";

import { RouteParams } from "../../routes";

import { SettingsEditAdminVisuals } from "./SettingsEditAdminVisuals";

type SettingsCreateAdminContainerProps = {
  navigation: StackNavigationProp<RouteParams, "SETTINGS_ADD_ADMIN">;
  route: RouteProp<RouteParams, "SETTINGS_ADD_ADMIN">;
};

export const SettingsCreateAdminContainer: FC<SettingsCreateAdminContainerProps> = ({
  route,
  navigation,
}) => {
  const [creatingAdmin, setCreatingAdmin] = useState(false);
  const [createAdminError, setCreateAdminError] = useState<string | undefined>(undefined);

  const onCreate = async (props: createNewAdminProps) => {
    setCreatingAdmin(true);
    try {
      await createNewAdmin(props);
    } catch (e) {
      setCreatingAdmin(false);
      setCreateAdminError(e.message);
      return;
    }

    setCreatingAdmin(false);
    setCreateAdminError(undefined);
    navigation.navigate("CONFIRMATION", {
      confirmationText: "The admin has been created",
      onContinue: () => navigation.navigate("ADMIN_SETTINGS", { mobileView: isMobileDevice() }),
    });
  };

  return (
    <SettingsEditAdminVisuals
      savingAdmin={creatingAdmin}
      checkSuperAdmin={route.params.isSuperAdmin}
      onSubmit={onCreate}
      saveAdminError={createAdminError}
    />
  );
};
