import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useState } from "react";

import { types } from "@covid/service";
import { HelpSiteType } from "@covid/service/graphql/types";
import {
  getUserFriendlyGraphqlErrorMessage,
  HELP_SITE_WITH_DETAILS,
  UpdateHelpSiteProps,
  validateHelpSiteInfo,
} from "@covid/service/src/dataItemManipulation";

import { RouteParams } from "../../routes";

import { HelpSitesEditVisuals } from "./HelpSitesEditVisuals";

type HelpSitesUpdateContainerProps = {
  navigation: StackNavigationProp<RouteParams, "HELP_SITE_UPDATE">;
  route: RouteProp<RouteParams, "HELP_SITE_UPDATE">;
};

/**
 * Container for update screen of Help sites using HelpSitesEditVisuals
 * @param navigation
 * @param route
 * @constructor
 */
export const HelpSitesUpdateContainer: FC<HelpSitesUpdateContainerProps> = ({
  navigation,
  route,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [updateHelpSiteMutation, { error, loading }] = types.useUpdateHelpSiteMutation();

  const [deleteHelpSiteMutation, deleteHelpSiteStatus] = types.useDeleteHelpSiteMutation();
  const [helpSiteToDelete, setHelpSiteToDelete] = useState<string | null>(null);

  const onUpdate = async ({
    location,
    id,
    additionalInfo,
    type,
    emailAddress,
    organisationName,
    phoneNumber,
    title,
  }: UpdateHelpSiteProps<HelpSiteType>) => {
    const errors = validateHelpSiteInfo({
      title,
      phoneNumber,
      emailAddress,
      location,
      type,
      locationID: route.params.report.locationID,
    });
    //TODO(JACK): Figure out why typescript requires this
    if (errors.length == 0 && id && location && type && title) {
      try {
        await updateHelpSiteMutation({
          variables: {
            additionalInformation: {
              source: additionalInfo || "",
              translationIndex: 1,
            },
            id: id,
            //TODO(Jack): Better typing here
            location: {
              longitude: location.longitude || 0,
              latitude: location.latitude || 0,
            },
            type: type,
            emailAddress: emailAddress === "" ? null : emailAddress,
            organisationName: organisationName,
            phoneNumber: phoneNumber === "" ? null : phoneNumber,
            title: { source: title, translationIndex: 0 },
          },
        });
      } catch (e) {
        errors.push(getUserFriendlyGraphqlErrorMessage(e.message));
      }

      if (errors.length == 0) {
        navigation.navigate("CONFIRMATION", {
          confirmationText: "The help site has been updated",
          onContinue: () => navigation.pop(2),
        });
      }
    }
    setValidationErrors(errors);
  };

  const onDelete = async () => {
    try {
      await deleteHelpSiteMutation({
        variables: {
          id: helpSiteToDelete,
        },
      });
      setHelpSiteToDelete(null);
    } catch (e) {
      //
    }
    if (!error) {
      navigation.navigate("CONFIRMATION", {
        confirmationText: "The help site has been deleted",
        onContinue: () => navigation.pop(2),
      });
    }
  };

  return (
    <HelpSitesEditVisuals
      dataItem={route.params.report}
      onUpdate={onUpdate}
      error={validationErrors.join(", ")}
      loading={loading}
      types={HELP_SITE_WITH_DETAILS}
      setDataItemToDelete={(id: string | null) => setHelpSiteToDelete(id)}
      dataItemToDelete={helpSiteToDelete}
      deleting={deleteHelpSiteStatus.loading}
      errorDeleting={deleteHelpSiteStatus.error?.message}
      deleteDataItem={onDelete}
      navigation={navigation}
    />
  );
};
