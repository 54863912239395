import { Card, Modal, StyleService } from "@ui-kitten/components";
import React, { FC, useState } from "react";
import { View } from "react-native";

import { screenStyles } from "@covid/common/src";
import { DropDownSelector } from "@covid/common/src/atoms";
import { ChangeLocationVisuals } from "@covid/common/src/organisms";
import { customTypes } from "@covid/service";

type LocationSelectWithModalProps = {
  currentLocation?: customTypes.AdminLocation;
  currentCountry?: customTypes.AdminLocation;
  fetchLocations: () => void;
  loading: boolean;
  locationsToDisplay: customTypes.AdminLocation[];
  selectLocation: (
    location: customTypes.AdminLocation,
    parentCountry?: customTypes.AdminLocation
  ) => void;
  countriesOnly?: boolean;
};
/**
 * Component that displays the currently selected location
 * when clicked uses a modal to present other location options
 * and alter location when selected
 * @constructor
 */
export const LocationSelectWithModalVisuals: FC<LocationSelectWithModalProps> = ({
  currentLocation,
  currentCountry,
  fetchLocations,
  loading,
  locationsToDisplay,
  selectLocation,
  countriesOnly,
}) => {
  // useEffect(() => {
  //   if (countriesOnly && currentLocation?.level === LocationLevel.City) {
  //     // Set location to the cities parent
  //     for (const country of locationsToDisplay) {
  //       const cities = fixNull(country.children?.items);
  //       for (const city of cities) {
  //         if (city.id === currentLocation.id) {
  //           selectLocation(country);
  //         }
  //       }
  //     }
  //   }
  // }, [locationsToDisplay]);

  const [modalVisible, setShowModal] = useState(false);

  const openModalWithLocations = () => {
    fetchLocations();
    setShowModal(true);
  };

  const renderModalWithLocations = () => (
    <Modal
      visible={modalVisible}
      backdropStyle={[screenStyles.modalBackdrop, screenStyles.fixedOrAbsolutePosition]}
      onBackdropPress={() => setShowModal(false)}
      style={[styles.modalStyle, styles.modalSize, screenStyles.fixedOrAbsolutePosition]}
    >
      <Card disabled style={styles.modalSize}>
        <View style={styles.modalSize}>
          <ChangeLocationVisuals
            selectLocationText={{ text: "Select Location" }}
            locations={locationsToDisplay}
            loading={loading}
            selectLocation={selectLocation}
            currentLocation={countriesOnly ? currentCountry : currentLocation}
            countriesOnly={countriesOnly}
            onPress={() => setShowModal(false)}
          />
        </View>
      </Card>
    </Modal>
  );

  return (
    <View>
      <DropDownSelector
        text={
          (countriesOnly ? currentCountry?.name : currentLocation?.name) || "NO LOCATION SELECTED"
        }
        onPress={() => openModalWithLocations()}
      />
      {renderModalWithLocations()}
    </View>
  );
};

const styles = StyleService.create({
  // Due to fixed position must apply to all children
  modalSize: {
    maxWidth: 400,
    maxHeight: 400,
  },
  modalStyle: {
    width: "90%",
    marginHorizontal: "auto",
    marginVertical: "auto",
  },
});
