import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Button, Card, Layout, Text } from "@ui-kitten/components";
import React, { FC } from "react";

import { screenStyles } from "@covid/common/src/styles";

import { RouteParams } from "../routes";

type ConfirmationScreenProps = {
  navigation: StackNavigationProp<RouteParams, "CONFIRMATION">;
  route: RouteProp<RouteParams, "CONFIRMATION">;
};

/**
 * Re-usable confirmation screen for use anywhere in admin site
 * @param route
 * @constructor
 */
export const ConfirmationScreen: FC<ConfirmationScreenProps> = ({ route }) => {
  return (
    <Layout style={screenStyles.webCentredColumn}>
      <Layout style={screenStyles.centeredInContainer}>
        <Card status={"success"} disabled>
          <Text style={screenStyles.centredTextWithMargin}>{route.params.confirmationText}</Text>

          <Button
            style={{ marginTop: 10, marginBottom: 10 }}
            size="giant"
            onPress={route.params.onContinue}
          >
            Continue
          </Button>
        </Card>
      </Layout>
    </Layout>
  );
};
