import { Card, Layout, CheckBox, Button, Spinner, Text, Divider } from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";

import { screenStyles } from "@covid/common/src";
import { Callout } from "@covid/common/src/molecules/Callout";
import { EditText } from "@covid/common/src/molecules/EditText";
import { UpdatePageBlock } from "@covid/common/src/molecules/UpdatePageBlock";
import { InformationModal } from "@covid/common/src/organisms";
import { createNewAdminProps, USER_GROUPS } from "@covid/service/src/adminActionApiFunctions";

type SettingsEditAdminVisualsProps = {
  initialEmail?: string;
  checkSuperAdmin?: boolean;
  onSubmit: (props: createNewAdminProps) => void;
  savingAdmin: boolean;
  saveAdminError?: string;
  onDisableEnable?: () => void;
  disableEnableError?: string;
  showDisable?: boolean;
  disablingEnabling?: boolean;
  onDelete?: () => void;
  deleting?: boolean;
  deletingError?: string;
  closeAllModals?: boolean;
};

export const SettingsEditAdminVisuals: FC<SettingsEditAdminVisualsProps> = ({
  checkSuperAdmin = false,
  initialEmail,
  onSubmit,
  savingAdmin,
  saveAdminError,
  disableEnableError,
  onDisableEnable,
  showDisable,
  disablingEnabling = false,
  deleting = false,
  onDelete,
  deletingError,
  closeAllModals = false,
}) => {
  const [email, setEmail] = useState("");
  const [superAdmin, setSuperAdmin] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEnableDisableModal, setShowEnableDisableModal] = useState(false);

  useEffect(() => {
    setSuperAdmin(checkSuperAdmin);
  }, [checkSuperAdmin]);

  useEffect(() => {
    if (closeAllModals) {
      setShowDeleteModal(false);
      setShowEnableDisableModal(false);
    }
  }, [closeAllModals]);

  //If options provided to visuals, displays buttons to disable and delete account
  const renderAccountManagementButtons = () => {
    if (initialEmail) {
      if (onDisableEnable && onDelete) {
        return (
          <>
            {/* Disable enable button*/}
            <Button
              style={screenStyles.buttonAtBottomOfForm}
              status={showDisable ? "warning" : "success"}
              onPress={() => setShowEnableDisableModal(true)}
            >
              {showDisable ? "Disable account" : "Enable account"}
            </Button>
            <InformationModal
              visibility={showEnableDisableModal}
              displayText={
                showDisable
                  ? "Disabling an admin will revoke all of their permissions; they will be unable to manage announcements, information pages etc. Are you sure you want to disable this admin?"
                  : "Enabling an admin will reintroduce all of their permissions; they will be able to manage announcements, information pages etc. Are you sure you want to enable this admin?"
              }
              onCancel={() => setShowEnableDisableModal(false)}
              onSubmit={onDisableEnable}
              submitText={showDisable ? "Disable" : "Enable"}
              submitStatus={showDisable ? "danger" : "success"}
              loading={disablingEnabling}
              error={disableEnableError}
            />
            <>
              {/* Delete button*/}
              <Button
                style={screenStyles.buttonAtBottomOfForm}
                status={"danger"}
                onPress={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
              <InformationModal
                visibility={showDeleteModal}
                displayText={
                  "Deleting an admin will delete their account and all their associated data, this cannot be undone. Are you sure you want to delete this admin?"
                }
                onCancel={() => setShowDeleteModal(false)}
                onSubmit={onDelete}
                submitText={"Delete account"}
                loading={deleting}
                error={deletingError || ""}
                typeToConfirmText={"DELETE"}
                typeToConfirmHeader={"Type the word DELETE to confirm"}
              />
            </>
          </>
        );
      }
    }
  };

  return (
    <Layout style={screenStyles.webCentredColumnNarrow}>
      <Card disabled style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        <UpdatePageBlock title={"Email address"}>
          {initialEmail ? (
            <Text>{initialEmail}</Text>
          ) : (
            <EditText onPress={setEmail} additionalInfo={email} />
          )}
        </UpdatePageBlock>

        <UpdatePageBlock title={"Super admin"}>
          <CheckBox checked={superAdmin} onChange={setSuperAdmin}>
            Check to assign this admin as a super admin
          </CheckBox>
          <Callout
            text={
              "Super admins can add, manage and delete other admins and super admins as well as being able to create new locations."
            }
            status={"basic"}
            icon={{ name: "exclamation-circle" }}
          />
        </UpdatePageBlock>
      </Card>
      {saveAdminError ? (
        <Callout status={"danger"} icon={{ name: "exclamation-circle" }} text={saveAdminError} />
      ) : null}
      <Button
        style={screenStyles.buttonAtBottomOfForm}
        appearance={savingAdmin ? "outline" : "filled"}
        accessoryRight={savingAdmin ? () => <Spinner status="primary" /> : undefined}
        onPress={() =>
          onSubmit({
            email: email,
            group: superAdmin ? USER_GROUPS.SUPER_ADMIN : USER_GROUPS.ADMIN,
          })
        }
      >
        Save changes
      </Button>
      <Divider style={{ marginTop: 10 }} />
      {renderAccountManagementButtons()}
    </Layout>
  );
};
