import { StackNavigationProp } from "@react-navigation/stack";
import { Button, Spinner, Text } from "@ui-kitten/components";
import React, { FC, useContext, useState } from "react";
import { View } from "react-native";

import { screenStyles } from "@covid/common/src";
import { LocationSelectMultiple, LocationSelectMultipleProps } from "@covid/common/src/organisms";
import { announcementCreateWithMultipleLocations, fixNull, types } from "@covid/service";

import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { ADMIN_GENERAL_DEFAULT_STATE, GeneralActionCreators } from "../../state/general";

type AnnouncementPostLocationSelectContainerProps = {
  navigation: StackNavigationProp<RouteParams, "ANNOUNCEMENT_SELECT_LOCATION">;
};

export const AnnouncementPostLocationSelectScreen: FC<AnnouncementPostLocationSelectContainerProps> = ({
  navigation,
}) => {
  const [{ general }, dispatch] = useContext(GlobalStateContext);
  const [selectedLocations, setLocations] = useState<LocationSelectMultipleProps["countries"]>([]);
  const [createAnnouncementError, setCreateAnnouncementError] = useState<string | null>(null);
  const [createAnnouncementLoading, setCreateAnnouncementLoading] = useState(false);
  const { data, error, client } = types.useListCountriesAndChildrenQuery({
    fetchPolicy: "cache-and-network",
  });

  const availableCountries = fixNull(data?.byLevel?.items);

  const createAnnouncementMutation = async (announcement: types.CreateAnnouncementInput) => {
    await announcementCreateWithMultipleLocations(
      client,
      announcement,
      selectedLocations.map((location) => location.id)
    );
  };

  const publishAnnouncement = async () => {
    const errors = [];
    if (general.currentAnnouncement) {
      setCreateAnnouncementLoading(true);
      try {
        await createAnnouncementMutation(general.currentAnnouncement);
      } catch (e) {
        setCreateAnnouncementError(e);
        errors.push(e.message);
      }
      setCreateAnnouncementLoading(false);
      setCreateAnnouncementError(null);
      setCreateAnnouncementLoading(false);

      if (!createAnnouncementError && errors.length === 0) {
        dispatch(
          GeneralActionCreators.updateAnnouncement(ADMIN_GENERAL_DEFAULT_STATE.currentAnnouncement)
        );

        navigation.navigate("CONFIRMATION", {
          confirmationText: "Your announcement has been sent to all users in these locations",
          onContinue: () => navigation.navigate("ANNOUNCE", {}),
        });
      }

      setCreateAnnouncementError(errors.join(", "));
    }
  };

  return (
    <View style={screenStyles.webCentredColumn}>
      <Text style={{ textAlign: "center", marginVertical: 16 }}>
        Where would you like to post this announcement?
      </Text>
      <LocationSelectMultiple
        countries={availableCountries || []}
        onChange={setLocations}
        error={createAnnouncementError || error?.message}
      />
      <Button
        style={screenStyles.bottomButton}
        appearance={createAnnouncementLoading ? "outline" : "filled"}
        onPress={publishAnnouncement}
        disabled={selectedLocations.length === 0 || createAnnouncementLoading}
        accessoryRight={createAnnouncementLoading ? () => <Spinner status="primary" /> : undefined}
      >
        Publish Announcement
      </Button>
    </View>
  );
};
