import { Button, IconProps, Input, Layout, Spinner, Text } from "@ui-kitten/components";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Image, StyleSheet, View } from "react-native";

import { TextLink } from "@covid/common/src/atoms";
import { VisibilityIcon } from "@covid/common/src/atoms/VisibilityIcon";
import { DEV_MODE } from "@covid/common/src/constants";
import { Callout } from "@covid/common/src/molecules/Callout";

export enum SIGN_IN_SCREENS {
  SIGN_IN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD_CONFIRM_EMAIL,
  FORGOT_PASSWORD_VERIFICATION_CODE,
}

type SignBoxProps = {
  screen: SIGN_IN_SCREENS;
  changeScreen: (screen: SIGN_IN_SCREENS) => void;
  signInAction: (email: string, password: string) => void;
  setNewPassword: (password: string) => void;
  forgotPasswordVerifyEmails: (email: string) => void;
  forgotPasswordResetPassword: (email: string, code: string, newPassword: string) => void;
  error?: string;
  loading: boolean;
  secureTextEntry: boolean;
  setSecureTextEntry: Dispatch<SetStateAction<boolean>>;
};

export const SignInVisuals: FC<SignBoxProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const renderVisibilityIcon = (visualProps: IconProps) => (
    <VisibilityIcon
      secureTextEntry={props.secureTextEntry}
      setSecureTextEntry={props.setSecureTextEntry}
      props={visualProps}
    />
  );

  const Errors = () =>
    props.error ? (
      <Callout status={"danger"} icon={{ name: "exclamation-circle" }} text={props.error} />
    ) : null;

  const renderChangePasswordScreen = () => (
    <Layout style={styles.signInBox}>
      <View style={styles.webCenteredColumn}>
        <Text category={"h3"}>New password required</Text>
        <Input
          label="New Password"
          onChangeText={setNewPassword}
          value={newPassword}
          secureTextEntry={props.secureTextEntry}
          accessoryRight={renderVisibilityIcon}
          autoCompleteType="password"
        />
        <Button
          style={styles.paddedButton}
          disabled={newPassword === ""}
          appearance={props.loading ? "outline" : "filled"}
          accessoryRight={props.loading ? () => <Spinner status="primary" /> : undefined}
          onPress={() => props.setNewPassword(newPassword)}
        >
          Change Password
        </Button>
        {Errors()}
      </View>
    </Layout>
  );

  const renderForgotPasswordScreen = () => (
    <Layout style={styles.signInBox}>
      <View style={styles.webCenteredColumn}>
        <Text category={"h3"}>Verify E-mail Address</Text>
        <Input label="Email" value={email} onChangeText={setEmail} autoCompleteType="email" />
        <Button
          onPress={() => props.forgotPasswordVerifyEmails(email)}
          style={styles.paddedButton}
          disabled={email === ""}
          appearance={props.loading ? "outline" : "filled"}
          accessoryRight={props.loading ? () => <Spinner status="primary" /> : undefined}
        >
          Verify E-mail
        </Button>
        {Errors()}
      </View>
    </Layout>
  );

  const renderVerificationCodeScreen = () => (
    <Layout style={styles.signInBox}>
      <View style={styles.webCenteredColumn}>
        <Text category={"h3"}>Enter Verification Code</Text>
        <Text>This will have been e-mailed to you. Please check junk if you can&#39;t find it</Text>
        <Input
          label="Verification Code"
          value={verificationCode}
          onChangeText={setVerificationCode}
        />
        <Input
          label="New Password"
          onChangeText={setNewPassword}
          value={newPassword}
          secureTextEntry={props.secureTextEntry}
          accessoryRight={renderVisibilityIcon}
          autoCompleteType="password"
        />
        <Button
          onPress={() => props.forgotPasswordResetPassword(email, verificationCode, newPassword)}
          style={styles.paddedButton}
          disabled={verificationCode === "" || newPassword === ""}
          appearance={props.loading ? "outline" : "filled"}
          accessoryRight={props.loading ? () => <Spinner status="primary" /> : undefined}
        >
          Change Password
        </Button>
        {Errors()}
      </View>
    </Layout>
  );

  const renderSignInScreen = () => (
    <Layout style={styles.signInBox}>
      <View style={styles.webCenteredColumn}>
        <Text category={"h2"} style={styles.centerText}>
          RefugeeHelpVirus
        </Text>
        <Image
          source={{
            uri:
              "https://refugee-covid-custom-uploads.s3-eu-west-1.amazonaws.com/app-icon-circle.png",
          }}
          style={{ height: 160, width: 160, alignSelf: "center" }}
        />
        <Text category={"h3"}>Admin Sign In</Text>
        <Input label="Email" value={email} onChangeText={setEmail} autoCompleteType="email" />
        <Input
          label="Password"
          onChangeText={setPassword}
          value={password}
          secureTextEntry={props.secureTextEntry}
          accessoryRight={renderVisibilityIcon}
          autoCompleteType="password"
        />
        {DEV_MODE && (
          <>
            <Text category={"h1"} style={styles.centerText}>
              TEST SITE
            </Text>
            <Text style={styles.centerText}>
              Changes made here will not affect the refugee users of the app and are simply to test
              and understand the functionality of the admin site.
            </Text>
          </>
        )}
        <Button
          onPress={() => props.signInAction(email, password)}
          style={styles.paddedButton}
          disabled={email === "" || password === ""}
          appearance={props.loading ? "outline" : "filled"}
          accessoryRight={props.loading ? () => <Spinner status="primary" /> : undefined}
        >
          Sign In
        </Button>

        <TextLink
          style={styles.textUnderButton}
          onPress={() => props.changeScreen(SIGN_IN_SCREENS.FORGOT_PASSWORD_CONFIRM_EMAIL)}
        >
          Forgot / reset password
        </TextLink>
        {Errors()}
      </View>
    </Layout>
  );

  switch (props.screen) {
    case SIGN_IN_SCREENS.CHANGE_PASSWORD:
      return renderChangePasswordScreen();
    case SIGN_IN_SCREENS.FORGOT_PASSWORD_CONFIRM_EMAIL:
      return renderForgotPasswordScreen();
    case SIGN_IN_SCREENS.FORGOT_PASSWORD_VERIFICATION_CODE:
      return renderVerificationCodeScreen();
    case SIGN_IN_SCREENS.SIGN_IN:
    default:
      return renderSignInScreen();
  }
};

const styles = StyleSheet.create({
  centerText: {
    textAlign: "center",
  },
  paddedButton: { marginTop: 16 },
  signInBox: { flex: 1, justifyContent: "center", padding: 10, width: "100%" },
  textUnderButton: {
    marginTop: 8,
  },
  webCenteredColumn: {
    marginHorizontal: "auto",
    maxWidth: 400,
    padding: 10,
    width: "100%",
  },
});
