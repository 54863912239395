import React, { FC, useContext } from "react";

import { customTypes, fixNull, types } from "@covid/service";

import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

import { LocationSelectWithModalVisuals } from "./LocationSelectWithModalVisuals";

type LocationSelectWithModalContainerProps = {
  onChange?: () => void;
  countriesOnly?: boolean;
};
/**
 * Component that displays the currently selected location
 * when clicked uses a modal to present other location options
 * and alter location when selected
 * @constructor
 */
export const LocationSelectWithModalContainer: FC<LocationSelectWithModalContainerProps> = ({
  onChange,
  countriesOnly,
}) => {
  const [
    fetchCountriesAndChildren,
    { data: locationResults, loading },
  ] = types.useListCountriesAndChildrenLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [
    {
      general: { currentLocation, currentCountry },
    },
    dispatch,
  ] = useContext(GlobalStateContext);

  const updateCurrentLocationInState = (
    locationSelected: customTypes.AdminLocation,
    parentCountry?: customTypes.AdminLocation
  ) => {
    dispatch(GeneralActionCreators.locationUpdate(locationSelected));
    dispatch(GeneralActionCreators.countryUpdate(parentCountry || locationSelected));
    onChange && onChange();
  };

  return (
    <LocationSelectWithModalVisuals
      currentLocation={currentLocation}
      currentCountry={currentCountry}
      fetchLocations={fetchCountriesAndChildren}
      loading={loading}
      locationsToDisplay={fixNull(locationResults?.byLevel?.items)}
      selectLocation={updateCurrentLocationInState}
      countriesOnly={countriesOnly}
    />
  );
};
