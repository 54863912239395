import { Button, Card, List, Text } from "@ui-kitten/components";
import React, { FC } from "react";
import { ListRenderItem, View } from "react-native";

import { DeleteButton } from "@covid/common/src/atoms";
import { isMobileDevice } from "@covid/common/src/constants";
import { LangsWithDetails } from "@covid/common/src/i18n";
import { AudioPlayer } from "@covid/common/src/molecules";
import { AnnouncementItem } from "@covid/common/src/molecules/AnnouncementItem";
import { Callout } from "@covid/common/src/molecules/Callout";
import { screenStyles } from "@covid/common/src/styles";
import {
  DEFAULT_ANNOUNCEMENT_CONTENT,
  DEFAULT_ANNOUNCEMENT_TITLE,
  getAudioTranslation,
  getTextTranslation,
} from "@covid/service";
import { AudioTranslationsInput, TextTranslationsInput } from "@covid/service/graphql/types";

type AnnouncementSummaryVisualsProps = {
  navigateToSelectLocation: () => void;
  sourceTitle: string;
  sourceContent: string;
  textTranslations: TextTranslationsInput[];
  audioTranslations: AudioTranslationsInput[];
  availableTextLangs: LangsWithDetails[];
  availableAudioLangs: LangsWithDetails[];
  onAddText: () => void;
  onAddAudio: () => void;
  onRemove: (audio: boolean, langID: string) => void;
};
/**
 * Shows the summary of the announcement before it is posted,
 * and allows checking over before upload.
 *
 */
//todo(jack): make genric card for english and other translations
export const AnnouncementSummaryVisuals: FC<AnnouncementSummaryVisualsProps> = ({
  navigateToSelectLocation,
  sourceTitle,
  sourceContent,
  textTranslations,
  audioTranslations,
  availableTextLangs,
  availableAudioLangs,
  onAddText,
  onAddAudio,
  onRemove,
}) => {
  const renderTextTranslations: ListRenderItem<TextTranslationsInput> = ({ item }) => (
    <>
      <Text category="label">{availableTextLangs.find((t) => t.id === item.langID)?.name}</Text>
      <AnnouncementItem
        heading={getTextTranslation(DEFAULT_ANNOUNCEMENT_TITLE, [item], item.langID)}
        content={getTextTranslation(DEFAULT_ANNOUNCEMENT_CONTENT, [item], item.langID)}
        createdAt={new Date()}
        accessoryRight={(props) => (
          <DeleteButton {...props} onDelete={() => onRemove(false, item.langID)} />
        )}
      />
    </>
  );

  const renderAudioTranslations: ListRenderItem<AudioTranslationsInput> = ({ item }) => (
    <Card style={{ marginVertical: 8 }}>
      <Text category={isMobileDevice() ? "h5" : "h3"}>
        {availableAudioLangs.find((t) => t.id === item.langID)?.name}
      </Text>
      <View style={screenStyles.centredRowWithSpaceBetween}>
        <AudioPlayer
          s3id={getAudioTranslation(DEFAULT_ANNOUNCEMENT_TITLE, [item], item.langID) || undefined}
        />
        <DeleteButton onDelete={() => onRemove(true, item.langID)} />
      </View>
    </Card>
  );

  return (
    <View style={screenStyles.webCentredColumn}>
      <View style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        <Callout
          icon={{ name: "info-circle" }}
          text="You can add translations to your announcement or upload it with English text only"
        />
        <AnnouncementItem heading={sourceTitle} content={sourceContent} createdAt={new Date()} />
        <List
          style={{ backgroundColor: "FFF" }}
          data={textTranslations}
          renderItem={renderTextTranslations}
        />
        <List
          style={{ backgroundColor: "FFF" }}
          data={audioTranslations}
          renderItem={renderAudioTranslations}
        />
      </View>
      <Button style={screenStyles.bottomButton} onPress={onAddText} appearance={"outline"}>
        Manage text translations
      </Button>
      <Button style={screenStyles.bottomButton} onPress={onAddAudio} appearance={"outline"}>
        Manage audio translations
      </Button>
      <Button style={screenStyles.bottomButton} onPress={navigateToSelectLocation}>
        Next - Select Locations
      </Button>
    </View>
  );
};
