import { StackScreenProps } from "@react-navigation/stack";
import React, { FC } from "react";

import { ManageTextTranslation } from "@covid/common/src/molecules/ManageTextTranslation";

import { RouteParams } from "../routes";

type PageManageAudioTranslationsScreenProps = StackScreenProps<
  RouteParams,
  "PAGE_TRANSLATE_AUDIO_MANAGE"
>;

export const PageManageAudioTranslationsScreen: FC<PageManageAudioTranslationsScreenProps> = ({
  navigation,
  route,
}) => {
  const { langName, translations, source, onSave } = route.params;

  return (
    <ManageTextTranslation
      languageName={langName}
      onSave={(updates) => onSave(updates)}
      onCancel={() => navigation.pop()}
      sources={source}
      translations={translations?.translations || {}}
      loading={false}
      audio={true}
    />
  );
};
